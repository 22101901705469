<template>
  <SingleProductTemplate :loading="true">
    <template v-slot:title>
      <Rectangle
        :height="{
          xs: 1,
        }"
      />
    </template>

    <template v-slot:id>
      <Rectangle :height="1" />
    </template>

    <template v-slot:image-container>
      <Rectangle :height="20" theme="dark" />
    </template>

    <template v-slot:product-meta>
      <Rectangle :height="1" />
    </template>

    <template v-slot:sidebar-header>
      <Rectangle :height="1" />
    </template>

    <template v-slot:sidebar-content>
      <Rectangle :height="18" />
    </template>

    <template v-slot:sidebar-button>
      <Rectangle :height="2" />
    </template>

    <template v-slot:attributes>
      <Rectangle :height="8" theme="dark" />
    </template>

    <template v-slot:tags-cloud>
      <Rectangle :height="8" theme="dark" />
    </template>

    <template v-slot:map>
      <Rectangle :height="12" theme="dark" />
    </template>

    <template v-slot:related-products>
      <Rectangle :height="12" />
    </template>
  </SingleProductTemplate>
</template>

<script>
import SingleProductTemplate from './single-product-template.vue';
import Rectangle from '../skeleton/rectangle.vue';

export default {
  name: 'SingleProductLoader',
  components: {
    Rectangle,
    SingleProductTemplate,
  },
};
</script>
