<template>
  <span class="tooltip">
    <span class="tooltip__label">
      <slot name="label" />
    </span>
    <span
      :class="{
        'tooltip__text--fixed-width': fixedWidth,
      }"
      class="tooltip__text"
    >
      <slot name="text" />
    </span>
  </span>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    fixedWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
