<template>
  <section
    :class="{
      'single-product--loading': loading,
    }"
    class="single-product"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-8">
          <header class="single-product__header">
            <h1 class="single-product__title">
              <slot name="title" />
            </h1>
            <span class="single-product__id">
              <slot name="id" />
            </span>
          </header>
          <!-- /.single-product__header -->

          <div
            :class="{
              'single-product__image-container--landscape': isLandscape,
              'single-product__image-container--portrait': isPortrait,
              'single-product__image-container--panoramic': isPanoramic,
            }"
            class="single-product__image-container"
          >
            <slot name="image-container" />

            <div class="single-product__nav">
              <slot name="nav" />
            </div>
          </div>

          <p class="single-product__meta">
            <slot name="product-meta" />
          </p>
        </div>
        <!-- /.col-* -->

        <div class="col-12 col-xl-4">
          <div class="single-product__order">
            <h2
              class="text text--xs-header-4 text--xs-mt-0 text--xs-mb-0 text--xs-uppercase"
            >
              <slot name="sidebar-header" />
            </h2>

            <div class="single-product__image-sizes">
              <slot name="sidebar-content" />
              <div class="single-product__button">
                <slot name="sidebar-button" />
              </div>
            </div>
            <!-- /.single-product__image-sizes -->

            <div class="single-product__add-to-favorites">
              <slot name="add-to-favorites" />
            </div>
          </div>
          <!-- /.single-product__order -->
        </div>
        <!-- /.col-* -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->

    <div class="single-product__details">
      <div class="container">
        <div class="row">
          <div class="order-2 order-lg-1 col-12 col-lg-6 col-xl-4 col-xxl-3">
            <slot name="attributes" />
          </div>
          <!-- /.col-* -->

          <div class="order-3 order-lg-2 col-12 col-lg-6 col-xl-4 offset-xxl-1">
            <div class="single-product__tags-cloud">
              <slot name="tags-cloud" />
            </div>
          </div>
          <!-- /.col-* -->

          <div class="order-1 order-lg-3 col-12 col-xl-4">
            <div class="single-product__map">
              <slot name="map" />
            </div>
          </div>
          <!-- /.col-* -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </div>
    <!-- /.single-product__details -->

    <slot name="after-details" />

    <div class="single-product__related">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <slot name="related-products" />
          </div>
          <!-- /.col-* -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </div>
    <!-- /.single-product__related -->
  </section>
</template>

<script>
export default {
  name: 'SingleProductTemplate',
  props: {
    isLandscape: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPortrait: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPanoramic: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
