<template>
  <div>
    <Loader v-if="loadingProduct" ref="product" />

    <SingleProductTemplate
      v-if="!loadingProduct"
      ref="product"
      :is-landscape="isLandscape"
      :is-panoramic="isPanoramic"
      :is-portrait="isPortrait"
    >
      <template v-slot:title>
        {{ product.title.rendered }}
      </template>

      <template v-slot:id> ID: {{ product.id }} </template>

      <template v-slot:image-container>
        <LazyImage
          :alt="product.title.rendered"
          :fit-to-parent="true"
          :stretch-horizontal="isPanoramic || isLandscape"
          :stretch-vertical="isPortrait"
          :large-image="product.attributes.image.large.url"
          :small-image="product.attributes.image.tiny.url"
        />
      </template>

      <template v-slot:nav>
        <ul v-if="hasNextProduct || hasPrevProduct" class="product-nav">
          <li class="product-nav__item product-nav__item--prev">
            <router-link
              v-if="hasPrevProduct"
              :to="prevProductRouteParams"
              class="product-nav__link"
            >
              Poprzednie zdjęcie
            </router-link>
          </li>
          <li class="product-nav__item product-nav__item--next">
            <router-link
              v-if="hasNextProduct"
              :to="nextProductRouteParams"
              class="product-nav__link"
            >
              Następne zdjęcie
            </router-link>
          </li>
        </ul>
      </template>

      <template v-slot:product-meta>
        <ul class="product-meta">
          <li class="product-meta__item">
            <span class="product-meta__label">
              Jakość:
            </span>
            <span class="product-meta__value">
              {{ product.attributes.quality.name }}
              <Tooltip :fixed-width="true">
                <template v-slot:label>
                  ( ? )
                </template>
                <template v-slot:text>
                  {{ qualityHelpText }}
                </template>
              </Tooltip>
            </span>
          </li>

          <li class="product-meta__item">
            <span class="product-meta__label">
              Data wykonania zdjęcia:
            </span>
            <span class="product-meta__value">
              {{ product.attributes.date | formatTimestamp }}
            </span>
          </li>
        </ul>
      </template>

      <template v-slot:sidebar-header>
        Rozmiar zdjęcia
      </template>

      <template v-slot:sidebar-content>
        <ul class="image-sizes">
          <li
            v-for="{
              sizeHumanReadable,
              size,
              width,
              height,
              price,
            } in imageSizes"
            :key="size"
            :class="{
              'image-sizes__item--active': size === selectedSize,
            }"
            class="image-sizes__item"
          >
            <input
              :id="size"
              v-model="selectedSize"
              :value="size"
              class="image-sizes__radio"
              name="image-size"
              type="radio"
            />
            <label :for="size" class="image-sizes__label">
              <span class="sr-only">
                {{ sizeHumanReadable }}
              </span>
            </label>

            <div class="image-sizes__name-and-desc">
              <span class="image-sizes__name">
                {{ sizeHumanReadable }}
              </span>

              <span class="image-sizes__desc">
                {{ width }}x{{ height }}px
              </span>
            </div>

            <div class="image-sizes__price-and-cart">
              <span class="image-sizes__price">
                {{ price }}
              </span>
              <Promised :promise="isProductInCart(size)">
                <template v-slot="data">
                  <span v-if="data === true" class="image-sizes__in-cart">
                    w koszyku
                  </span>
                  <span v-else />
                </template>
              </Promised>
            </div>
          </li>
        </ul>
      </template>

      <template v-slot:sidebar-button>
        <button
          :class="{
            'button--loading': loadingCart,
          }"
          class="button button--large button--full button--alt"
          @click="cartButtonOnClick"
        >
          <template v-if="isSelectedProductInCart">
            Usuń z koszyka
          </template>
          <template v-else>
            Dodaj do koszyka
          </template>
        </button>
      </template>

      <template v-slot:add-to-favorites>
        <WishlistButton :id="product.id" class="add-to-favorites">
          <template #default="{ inWishlist, text }">
            <span
              :class="{
                'add-to-favorites__ico--heart': inWishlist,
                'add-to-favorites__ico--heart-outline': !inWishlist,
              }"
              class="add-to-favorites__ico"
            >
              {{ text }}
            </span>
          </template>
        </WishlistButton>
      </template>

      <template v-slot:attributes>
        <ul class="attributes">
          <template v-for="{ attribute, label, values } in multipleAttributes">
            <li
              v-if="values.length"
              :key="`label-${attribute}`"
              class="attributes__item"
            >
              <p :key="`label-${attribute}`" class="attributes__label">
                {{ label }}&colon;
              </p>
              <p :key="`value-${attribute}`" class="attributes__value">
                <router-link
                  v-for="{ name, slug, count } in values"
                  :key="`${attribute}-${slug}`"
                  :to="getRouteParams(attribute, slug)"
                  class="attributes__link attributes__link--multiple"
                >
                  {{ name }}
                  <span class="attributes__count"> ({{ count }}) </span>
                </router-link>
              </p>
            </li>
          </template>

          <template
            v-for="{ attribute, label, name, slug, count } in attributes"
          >
            <li v-if="count" :key="`label-${slug}`" class="attributes__item">
              <p :key="`label-${slug}`" class="attributes__label">
                {{ label }}&colon;
              </p>
              <p :key="`value-${slug}`" class="attributes__value">
                <router-link
                  :to="getRouteParams(attribute, slug)"
                  class="attributes__link"
                >
                  {{ name }}
                  <span class="attributes__count"> ({{ count }}) </span>
                </router-link>
              </p>
            </li>
          </template>
        </ul>
      </template>

      <template v-slot:tags-cloud>
        <ul class="tags-cloud">
          <li
            v-for="term in product.attributes.tags"
            :key="term.id"
            class="tags-cloud__item"
          >
            <router-link
              :to="{
                name: 'search-results',
                query: {
                  searchPhrase: term.name,
                },
              }"
              class="tags-cloud__link"
            >
              {{ term.name }} ({{ term.count }})
            </router-link>
          </li>
        </ul>
        <!-- /.tags-cloud -->
      </template>

      <template v-if="marker" v-slot:map>
        <MapWithMarkers
          :center="latLng"
          :markers="marker"
          :max-zoom="16"
          :min-zoom="8"
          :zoom="11"
        />
        <div class="single-product__map-button">
          <router-link
            :to="getPhotosNearbyRouterParams()"
            class="button button--small button--alt"
          >
            zobacz zdjęcia z okolicy
          </router-link>
        </div>
      </template>

      <template v-slot:related-products>
        <h2
          class="text text--xs-header-4 text--xs-uppercase text--xs-gray text--xs-mt-0"
        >
          Zobacz także:
        </h2>

        <related-products :types="types" />
      </template>
    </SingleProductTemplate>
  </div>
</template>

<script>
import 'tocca';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Promised } from 'vue-promised';
import get from 'lodash/get';
import transform from 'lodash/transform';
import RelatedProducts from '../related-products/related-products.vue';
import getProductOrientation from '../../utils/getProductOrientation';
import LazyImage from '../lazy-image/lazy-image.vue';
import MapWithMarkers from '../map-with-markers/map-with-markers.vue';
import SingleProductTemplate from './single-product-template.vue';
import Loader from './single-product-loader.vue';
import Tooltip from '../tooltip/tooltip.vue';
import WishlistButton from '../wishlist-button/wishlist-button.vue';

export default {
  name: 'SingleProduct',
  components: {
    WishlistButton,
    Tooltip,
    MapWithMarkers,
    RelatedProducts,
    LazyImage,
    Promised,
    SingleProductTemplate,
    Loader,
  },
  filters: {
    formatTimestamp(timestamp) {
      const date = new Date(timestamp * 1000);

      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);

      return `${year}-${month}-${day}`;
    },
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: '%s | fotoregiony.pl',
    };
  },
  data: () => ({
    loadingProduct: true,
    selectedSize: '',
    inWishlist: false,
  }),
  computed: {
    ...mapState('product', ['product', 'childProducts']),
    ...mapGetters('cart', {
      loadingCart: 'loading',
    }),
    title() {
      return get(this.product, 'title.rendered');
    },
    marker() {
      if (!this.product) {
        return [];
      }

      return [
        {
          id: this.product.id,
          slug: this.product.slug,
          orientation: this.orientation,
          lat: this.latLng.lat,
          lng: this.latLng.lng,
          image: get(this.product, 'attributes.image.small.url') || '',
          title: this.product.title.rendered,
        },
      ];
    },
    latLng() {
      if (!this.product) {
        return {
          lat: 0,
          lng: 0,
        };
      }
      return {
        lat: parseFloat(this.product.attributes.cords.lat),
        lng: parseFloat(this.product.attributes.cords.lng),
      };
    },
    types() {
      return this.product.attributes.type.map(({ slug }) => slug);
    },
    orientation() {
      return getProductOrientation(this.product);
    },
    isPanoramic() {
      return this.product.attributes.panoramic.slug === 'yes';
    },
    isLandscape() {
      return this.orientation === 'landscape';
    },
    isPortrait() {
      return this.orientation === 'portrait';
    },
    imageSizes() {
      return this.childProducts;
    },
    hasNextProduct() {
      return this.product.next !== '';
    },
    hasPrevProduct() {
      return this.product.prev !== '';
    },
    prevProductRouteParams() {
      return {
        name: 'single-product',
        params: {
          slug: this.product.prev,
        },
      };
    },
    nextProductRouteParams() {
      return {
        name: 'single-product',
        params: {
          slug: this.product.prev,
        },
      };
    },
    attributes() {
      const labels = {
        season: 'Pora roku',
        'time-of-day': 'Pora dnia',
        city: 'Miasto',
        place: 'Miejsce',
      };

      return ['season', 'time-of-day', 'city', 'place'].map(attribute => {
        const { name, slug, count } = this.product.attributes[attribute];
        return {
          attribute,
          name,
          slug,
          count,
          label: labels[attribute],
        };
      });
    },
    multipleAttributes() {
      return transform(
        {
          'view-on': 'Widok na',
          'view-from': 'Widok z',
        },
        (result, label, attribute) => {
          const attributes = this.product.attributes[attribute];

          if (!attributes) {
            return result;
          }

          return {
            ...result,
            [attribute]: {
              attribute,
              label,
              values: attributes.map(({ name, slug, count }) => ({
                name,
                slug,
                count,
              })),
            },
          };
        },
        {}
      );
    },
    qualityHelpText() {
      const texts = {
        basic: 'Zdjęcia dokumentacyjne o niskich walorach artystycznych',
        normal:
          'Zdjęcia poprawne technicznie, o niewybitnych walorach artystycznych',
        premium:
          'Fotografie PREMIUM - charakteryzują się wyjątkowymi walorami artystycznymi lub sprzedażowymi',
      };

      return texts[this.product.attributes.quality.slug];
    },
  },
  watch: {
    $route: 'fetchProduct',
  },
  async created() {
    this.fetchProduct();
  },
  mounted() {
    const isTouchScreen =
      'ontouchstart' in window || navigator.msMaxTouchPoints;

    if (!isTouchScreen) {
      return;
    }

    this.$refs.product.addEventListener('swiperight', () => {
      this.$router.push(this.prevProductRouteParams);
    });
    this.$refs.product.addEventListener('swipeleft', () => {
      this.$router.push(this.nextProductRouteParams);
    });
  },
  asyncComputed: {
    isSelectedProductInCart: {
      get() {
        if (this.loadingProduct) {
          return false;
        }

        return this.isProductInCart(this.selectedSize);
      },
      default: false,
      watch: ['loadingCart'],
    },
  },
  methods: {
    ...mapGetters('product', ['getAttributeName', 'getAttributeCount']),
    ...mapActions('cart', {
      addToCart: 'add',
      removeFromCart: 'remove',
    }),
    getRouteParams(prop, value) {
      return {
        name: 'search-results',
        query: {
          [prop]: value,
        },
      };
    },
    getPhotosNearbyRouterParams() {
      return {
        name: 'search-results',
        query: {
          lat: this.latLng.lat,
          lng: this.latLng.lng,
          layout: 'map',
        },
      };
    },
    async fetchProduct() {
      try {
        this.loadingProduct = true;
        await this.$store.dispatch('product/fetchProduct', {
          slug: this.$route.params.slug,
        });

        const sizes = ['sm', 'md', 'lg', 'xl'];
        const sizesMapped = await Promise.all(
          sizes.map(async size => {
            const inCart = await this.isProductInCart(size);

            return {
              size,
              inCart,
            };
          })
        );

        const found = sizesMapped.find(({ inCart }) => inCart === true);

        this.selectedSize = found ? found.size : 'md';
        this.loadingProduct = false;
      } catch (e) {
        this.$bugsnag.notify(e);
        this.loadingProduct = false;
      }
    },
    async cartButtonOnClick() {
      if (this.loadingCart) {
        return;
      }

      if (this.isSelectedProductInCart) {
        this.removeFromCart(this.product.id);
        return;
      }

      await this.removeFromCart(this.product.id);

      this.addToCart({
        id: this.product.id,
        size: this.selectedSize,
      });
    },
    isProductInCart(size) {
      return this.$store.getters['cart/inCart']({
        size,
        id: this.product.id,
      });
    },
  },
};
</script>
